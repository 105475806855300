import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { consLogged } from "../constants/consLogged";
import { LayoutPage } from "../layout/LayoutPage";
import Login from "../views/auth/Login";
import { Home2 } from "../views/home2/Home2";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Loading from "../components/loading/Loading";
import { verificaLogin } from "../context/actions/loginActions";
import { Detalle } from "../views/home2/detalle/Detalle";

import { InformacionOrden } from "../views/facturacion/InformacionOrden/InformacionOrden";
import { CrearOrden } from "../views/facturacion/crearOrden/CrearOrden";
import { OrdenesAbiertas } from "../views/facturacion/OrdenesAbiertas";
import { OrdenPago } from "../views/facturacion/ordenPago/OrdenPago";
import { Spin } from "antd";
import { VersionFront } from "./VersionFront";

export default function Routes() {
  const dispatch = useDispatch();
  const { logged } = useSelector((state) => state.userReducer);
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(verificaLogin());
  }, []);

  if (logged === consLogged.INICIANDO) return <Loading />;

  return (
    <Router>
      <div
        style={{
          height: "100vh",
          width: 15,
          backgroundColor: "#20bab0",
          position: "fixed",
          zIndex: 999,
        }}
      ></div>
      <Switch>
        <Route component={VersionFront}>
          <PublicRoute
            isAuthenticated={logged}
            exact
            path="/login"
            component={Login}
          />
          <LayoutPage>
            <Suspense
              fallback={
                <div style={{ marginTop: 30 }}>
                  {" "}
                  <Spin size="large" />
                </div>
              }
            >
              <PrivateRoute
                isAuthenticated={logged}
                exact
                path="/facturacion/ordenes-abiertas"
                component={OrdenesAbiertas}
              />

              <PrivateRoute
                isAuthenticated={logged}
                exact
                path="/facturacion/:id/crear-orden"
                component={CrearOrden}
              />
              <PrivateRoute
                isAuthenticated={logged}
                exact
                path="/facturacion/orden-pago/:id"
                component={OrdenPago}
              />

              <PrivateRoute
                isAuthenticated={logged}
                exact
                path="/facturacion/:id/informacion-orden"
                component={InformacionOrden}
              />

              <PrivateRoute
                isAuthenticated={logged}
                exact
                path="/"
                component={Home2}
              />
              <PrivateRoute
                isAuthenticated={logged}
                exact
                path="/detalle/:asistenciaID"
                component={Detalle}
              />
            </Suspense>
          </LayoutPage>
        </Route>
      </Switch>
    </Router>
  );
}
