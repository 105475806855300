import { FileAddFilled, SolutionOutlined, UploadOutlined } from "@ant-design/icons";


export const useStepsOrdenPago = () =>  {
  const items = [
    {
      title: `Orden creada`,
    },
    {
      title: 'Fase 1',

      description: 'Agregando servicios',
      icon: <FileAddFilled/>
    },
    {
      title: 'Fase 2',
      description: 'Subiendo archivos',
      icon: <UploadOutlined/>
    },
    {
      title: 'Fase 3',
      description: 'Archivos en validación',
      icon: <SolutionOutlined/>
    }

  ]
  return {items}
}