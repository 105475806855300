import CardPage from "../../../layout/CardPage";
import { useParams } from "react-router";
import { Button, notification, Steps } from "antd";
import { useStepsOrdenPago } from "./useStepsOrdenPago";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getDetailFactura, saveDetailFactura } from "../../../context/actions/facturacionActions";
import { RightCircleFilled } from "@ant-design/icons";
import { Fase1 } from "./Fase1/Fase1";
import { Fase2 } from "./Fase2/Fase2";
import { Fase3 } from "./Fase3/Fase3";

export const OrdenPago = () => {
  const [state, setState] = useState(1)

  const {id} = useParams()

  const {items} = useStepsOrdenPago()

  const services = useSelector(state => state.facturacionReducer.itemsFacturacion)


  const dispatch = useDispatch();
  const nextState = () => {
    if (state === 1) {
      const anyWithoutPlate = services.some(f => f.tienePlaca !== true)
      if (anyWithoutPlate) {
        notification.error({message: 'Existen uno o mas servicios sin placa cargada'})
      }
    }

    setState(prev => prev + 1)
  }

  useEffect(() => {
    void dispatch(getDetailFactura(id))
    return () => {
      dispatch(saveDetailFactura({}))
    }
  }, []);
  return (
    <>
      <CardPage titulo={`Orden de pago ${id}`}>
        <Steps items={items} current={state} style={{marginBottom: '20px'}}/>

        {state === 1 && <Fase1/>}

        {state === 2 && <Fase2/>}

        {state === 3 && <Fase3/>}

        <Button style={{marginTop: '30px'}} onClick={nextState} icon={<RightCircleFilled/>} type='primary'
                danger> Next </Button>
      </CardPage>
    </>
  )
}

