export const getCronoTime = (time, less) => {

    const f = new Date(time)
    const mm = Number(f.getMinutes()) > 9 ? f.getMinutes() : `0${f.getMinutes()}`;
    const ss = Number(f.getSeconds()) > 9 ? f.getSeconds() : `0${f.getSeconds()}`;
    const mil = Number(
        f.getMilliseconds()) > 9 ? (f.getMilliseconds() > 99 ? f.getMilliseconds() : `0${f.getMilliseconds()}`) : `00${f.getMilliseconds()}`;

    return `${mm - Number(less)}:${ss}:${mil}`

}
