import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RowAsistenciaPorConfirmar from "./RowAsistenciaPorConfirmar";
import { noPresentarAun } from "./noPresentarAun";
import { useSound } from "./useSound";

export const TableBody = () => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const { asistenciasPorConfirmar } = useSelector(
    (state) => state.asistenciasReducer
  );

  const { RetrasoAsgigAuto } = useSelector((state) => state.userReducer.user);
  const { proveedorID } = useSelector((state) => state.userReducer.user);

  // useSound();

  if (asistenciasPorConfirmar.length === 0)
    return (
      <div style={{ marginTop: 30, marginBottom: 30 }}>Sin servicios...</div>
    );

  //

  ////////////////////
  return (
    <>
      {asistenciasPorConfirmar.map((a, i) => {
        if (noPresentarAun(a.fechaSugiereProveedor, RetrasoAsgigAuto))
          return <></>;

        const provAsignado = a.estatusID >= 3 && a.proveedorID !== proveedorID;

        return (
          <RowAsistenciaPorConfirmar key={a.asistenciaID} asistencia={a} provAsignado={provAsignado} />
        );
      })}


    </>
  );
};
