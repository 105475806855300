import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCronoTime } from '../../../utils/getCronoTime'

export const Crono = ({ fechaSugiereProveedor }) => {

    const { RetrasoAsgigAuto } = useSelector(state => state.userReducer.user)
    const date1 = Date.parse(fechaSugiereProveedor)
    const [dif, setDif] = useState()

    useEffect(() => {

        const inter = setInterval(() => {

            const diffTime = Math.abs(Date.now() - (date1));

            setDif(getCronoTime(diffTime, RetrasoAsgigAuto || 0))
        }, 1)

        return () => clearInterval(inter)
    }, [])

    return (
        <div className='crono'>{dif}</div>
    )
}
