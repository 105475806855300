import { UploadZone } from "./UploadZone";
import { Alert } from "antd";
import { UploadItem } from "./UploadItem";

export const StatusZone = ({status}) => {
  if (status === 1 || status === 2) {
    return <UploadZone/>
  }

  if (status === 3) {
    return <Alert type={'info'} message={'Estamos validando los documentos, en breve recibirá noticias'} showIcon/>
  }

  if (status === 4) {
    return <Alert type={'success'} message={'Hemos validado sus documentos, en espera de autorización de pago'}
                  showIcon/>
  }

  if (status === 5) {
    return <Alert type={'success'} message={'Hemos autorizado su pago'} showIcon/>
  }


  if (status === 6) {
    return <>
      <Alert type={'success'} message={'Hemos efectuado su pago, ahora puede subir su comprobante de pago'}
             showIcon/>
      <div style={{width: '30%', marginTop: '30px'}}>

        <UploadItem title={"Cargar comprobante de pago"} tipoDocumento={4}
                    color={'red'}/>

      </div>
    </>
  }
}
