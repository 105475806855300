import CardPage from "../../layout/CardPage";
import {TablaOrdenesAbiertas} from "./components/TablaOrdenesAbiertas";
import {ControlesOrdenesAbiertas} from "./components/ControlesOrdenesAbiertas";

export const OrdenesAbiertas = () => {
    return (
        <>
            <CardPage titulo={"Ordenes abiertas"}>
                <ControlesOrdenesAbiertas style={{marginBottom: '20px'}}/>

                <TablaOrdenesAbiertas/>
            </CardPage>
        </>
    )
}