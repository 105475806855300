import dayjs from 'dayjs'
import 'dayjs/locale/es'

dayjs.locale('es') // use locale globally

export const DayjsConfig = (format) => {
  return (date) => {
    return dayjs(date).format(format)
  }
}
