import { ButtonUpload } from "./ButtonUpload";
import { useParams } from "react-router";
import { StatusFile } from "./StatusFile";

export const UploadItem = ({title, icon, color, tipoDocumento, boolean, message, size = 'small', accept, fn, disabled, estaCargada},) => {
  const tipo = title.split(' ').at(-1)
  const {id} = useParams()
  const body = {
    tipoDocumento,
    ordenPagoID: id
  }

  return (
    <article style={{display: 'grid', gridTemplateColumns: '2fr 1fr 2fr', marginBottom: '10px'}}>
      <h3 style={{fontSize: '14px'}}>{title} <span style={{color: color}}> {icon}</span></h3>
      <ButtonUpload accept={accept} size={size} title={tipo.toUpperCase()} fn={fn} body={body} disabled={disabled}
                    url={"/APDocumentos/PostDocumento"}/>

      <StatusFile
        estaCargada={estaCargada}
        archivoValido={boolean}
        message={message}
      />

    </article>

  )
}