import { formatNumberIntl } from "../../../../utils/formatNumberIntl";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { axiosClientSigsa } from "../../../../lib/axios/axiosClientSigsa";
import { getItemsOrdenFacturacion } from "../../../../context/actions/facturacionActions";
import { useParams } from "react-router";
import { DayjsConfig } from "../../../../lib/Dayjs/DayjsConfig";

export const useColumnsInformacion = () => {

  const ordenDetails = useSelector(state => state.facturacionReducer.detailsFactura)
  const {id} = useParams()
  const dispatch = useDispatch()
  const format = DayjsConfig("DD/MM/YYYY");
  const deleteOrden = async (ordenPagoId) => {
    try {
      await axiosClientSigsa(`APFacturacion/RemoveItemOP/${ordenPagoId}`)
      message.success('Eliminado')
      void dispatch(getItemsOrdenFacturacion(id))
    } catch (e) {
      message.error(e.response.data)
    }

  }
  const columns = [
    {
      title: 'Asistencia ID',
      dataIndex: 'asistenciaID',
      key: 'asistenciaID',
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha',
      key: 'fecha',
      render: (fecha) => format(fecha),
    },
    {
      title: 'Clave SAT',
      dataIndex: 'claveSAT',
      key: 'claveSAT',
    },
    {
      title: 'Servicio',
      dataIndex: 'servicio',
      key: 'servicio',
    },
    {
      title: 'Local / Carretero',
      dataIndex: 'localCarretero',
      key: 'localCarretero',
    },
    {
      title: 'Costo',
      dataIndex: 'monto',
      key: 'monto',
    },
    {
      title: 'IVA',
      dataIndex: 'iva',
      key: 'iva',
    },
    {
      title: 'IVA R',
      dataIndex: 'ivaRetenido',
      key: 'ivaRetenido',
    },
    {
      title: 'ISR',
      dataIndex: 'isrRetenido',
      key: 'isrRetenido',
    },
    {
      title: 'Total',
      dataIndex: 'montoTotal',
      key: 'montoTotal',
      render: (text, record) => {
        return (
          <span>
            {formatNumberIntl(record.montoTotal)}
          </span>
        )
      }
    },
/*
    {
      title: '',
      render: (row) => <>{(ordenDetails.estatusOrdenPagoID === 1 || ordenDetails.estatusOrdenPagoID === 2) &&
        <Tooltip title={'Eliminar'}> <Button onClick={() => deleteOrden(row.ordenPagoItemID)}
                                             icon={<DeleteOutlined/>} type={'text'}
                                             danger></Button></Tooltip>}
      </>
    }
*/

  ]
  return {columns}
}