import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startGetAsistenciasPorConfirmar } from "../../../context/actions/asistenciasActions";
import CardPage from "../../../layout/CardPage";
import { TablaHeader } from "./TablaHeader";
import { TableBody } from "./TableBody";

export const AsistenciasPorConfirmar = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startGetAsistenciasPorConfirmar());
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(startGetAsistenciasPorConfirmar());
    }, 20000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <CardPage titulo="Servicios por Aceptar">
      <TablaHeader />
      <TableBody />
    </CardPage>
  );
};
