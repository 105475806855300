import CardPage from "../../../layout/CardPage";
import { ControlesCrearOrden } from "../ordenPago/Fase1/ControllesCrearOrden";
import { Button } from "antd";
import { TablaCrearOrden } from "../ordenPago/Fase1/TablaCrearOrden";
import { useHistory, useParams } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDetailFactura, saveDetailFactura } from "../../../context/actions/facturacionActions";

export const CrearOrden = () => {
  const history = useHistory();
  const {id} = useParams();
  const gotoInformacionOrde = () => {
    history.push(`/facturacion/${id}/informacion-orden`)
  }


  const details = useSelector(state => state.facturacionReducer.detailsFactura)

  const dispatch = useDispatch();
  useEffect(() => {
    void dispatch(getDetailFactura(id))
    return () => {
      dispatch(saveDetailFactura({}))
    }
  }, []);


  return (
    <>
      <CardPage titulo={`Orden de pago ${id} `} tituloBoton={'Regresar'}
                accionBoton={() => history.push('/facturacion/ordenes-abiertas')}>

        <ControlesCrearOrden style={{marginBottom: '15px'}}/>


        <TablaCrearOrden/>
        <Button onClick={gotoInformacionOrde} type={"primary"}
                style={{display: 'block', marginLeft: 'auto', marginRight: '0px', marginTop: '20px'}}>Ver detalles
          Orden</Button>
      </CardPage>

    </>
  )
}