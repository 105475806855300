import { MessageTwoTone } from "@ant-design/icons";
import { Badge, Col, Row } from "antd";
import { useHistory } from "react-router";
import { dateFromString } from "../../../utils/dateFromString";
import { truncateString } from "../../../utils/truncateString";

const RowAsistencia = ({ a }) => {
  const history = useHistory();

  const onClick = () => {
    history.push(`/detalle/${a.asistenciaID}`);
  };

  return (
    <Badge.Ribbon
      text={a.localCarretero}
      color={a.localCarretero === "local" ? "green" : "blue"}
    >
      <Row
        gutter={10}
        style={{ marginBottom: 15 }}
        className="rowAsistencias"
        onClick={onClick}
      >
        <Col span={2} className="">
          {a.asistenciaID}
        </Col>

        <Col span={2} className="">
          {dateFromString(a.fechaLlamadaIniciada).dateStep}
        </Col>
        <Col span={2} className="">
          {dateFromString(a.fechaAsignacion).dateStep}
        </Col>
        <Col span={2} className="">
          {dateFromString(a.fechaArribo).dateStep}
        </Col>
        <Col span={3} className="">
          {truncateString(
            a.nombreLlamada +
              " " +
              a.apellidoPaternoLlamada +
              " " +
              a.apellidoMaternoLlamada,
            26
          )}
        </Col>
        <Col span={2} className="">
          {a.servicioNombre}
        </Col>
        <Col span={4} className="">
          {a.municipioOrigen}, {a.estadoOrigen}
        </Col>
        <Col span={4} className="">
          {a.municipioDestino}, {a.estadoDestino}
        </Col>
        <Col span={2} className="">
          {a.estatusNombre}
          {"   "}
        </Col>
      </Row>
    </Badge.Ribbon>
  );
};

export default RowAsistencia;
