import { Col, Divider, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { LabelValor } from "../../../../components/label/LabelValor";
import CardPage from "../../../../layout/CardPage";
import { Controles } from "../controles/Controles";
import { SplitCampo } from "./SplitCampo";

export const DatosAsistencia = () => {
  const history = useHistory();
  const { asistencia: a } = useSelector((state) => state.asistenciasReducer);

  return (
    <CardPage
      titulo="Datos de Asistencia"
      tituloBoton="Regresar"
      accionBoton={() => history.push("/")}
    >
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <LabelValor
            label="Titular"
            valor={`${a.nombreLlamada} ${a.apellidoPaternoLlamada}`}
          />
        </Col>
        <Col span={6}>
          <LabelValor label="Servicio" valor={a.servicio?.nombre} />
        </Col>

        <Col span={6}>
          <LabelValor label="Estatus" valor={a.estatus?.nombre} />
        </Col>

        <SplitCampo campo={a.campo1} />
        <SplitCampo campo={a.campo2} />
        <SplitCampo campo={a.campo3} />
        <SplitCampo campo={a.campo4} />
        <SplitCampo campo={a.campo5} />
        <SplitCampo campo={a.campo6} />
        <SplitCampo campo={a.campo7} />
        <SplitCampo campo={a.campo8} />
        <SplitCampo campo={a.campo9} />
        <SplitCampo campo={a.campo10} />
        <SplitCampo campo={a.campo11} />
        <SplitCampo campo={a.campo12} />
      </Row>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <LabelValor
            label="Estado origen"
            valor={`${a.municipioOrigen}, ${a.estadoOrigen}`}
          />
        </Col>

        {a.estadoDestino !== "" && (<Col span={6}>
          <LabelValor
            label="Estado destino"
            valor={`${a.municipioDestino}, ${a.estadoDestino}`}
          />
        </Col>)}


      </Row>

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelValor
            label="LatLng origen"
            valor={`${a.latOrigen}, ${a.lngOrigen}`}
          />
        </Col>

        {a.latDestino !== "" && (
          <Col span={12}>
            <LabelValor
              label="LatLng destino"
              valor={`${a.latDestino}, ${a.lngDestino}`}
            />
          </Col>
        )}

      </Row>

      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col span={12}>
          <LabelValor label="Dirección origen" valor={a.direccionOrigen} />
        </Col>

        {a.direccionDestino !== "" && (
          <Col span={12}>
            <LabelValor label="Dirección destino" valor={a.direccionDestino} />
          </Col>
        )}

      </Row>
      <Divider />
      <Controles />
    </CardPage>
  );
};
