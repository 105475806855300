export const types = {
  setLoadingStatus: "[loadingAction] setLoadingStatus",
  setCalculing: "[loadingAction] setCalculing",
  setLoginErr: "[authErrAcion] setLoginErr",
  storeUserData: "[loginAcion] storeUserData",
  setLogged: "[loginAcion] setLogged",

  //// notificaciones
  notification: "[notificacionAction] notification",
  storeVersionBackend: "[notificacionAction] storeVersionBackend",

  //// asistencias
  storeAsistenciasPorConfirmar:
    "[asistenciasAction] storeAsistenciasPorConfirmar",
  storeAsistenciasVivas: "[asistenciasAction] storeAsistenciasVivas",
  setOpenLateral: "[asistenciasAction] setOpenLateral",
  storeAsistencia: "[asistenciasAction] storeAsistencia",
  storeArchivos: "[asistenciasAction] storeArchivos",
  setCargandoAsistencia: "[asistenciasAction] setCargandoAsistencia",

  //// Otros
  storeProveedorInfo: "[asistenciasAction] storeProveedorInfo",
  setConfirmaAsistenciaData: "[asistenciasAction] setConfirmaAsistenciaData",

  handleModalVersion: "[facturacionAction] handleModalVersion",
};
