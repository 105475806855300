import { useColumnsCrearOrden } from "../../hooks/useColumnsCrearOrden";
import { Table } from "antd";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItemsOrdenFacturacion, saveItemsFacturacion } from "../../../../context/actions/facturacionActions";

export const TablaCrearOrden = () => {
  const {columns} = useColumnsCrearOrden()
  const {id} = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    void dispatch(getItemsOrdenFacturacion(id))
    return () => {
      dispatch(saveItemsFacturacion([]))
    }
  }, []);
  const items = useSelector(state => state.facturacionReducer.itemsFacturacion)
  return (
    <>
      <Table size={'small'} rowKey={'asistenciaID'} columns={columns} dataSource={items}/>
    </>
  )
}