import { useDataOrdenesAbiertas } from "../hooks/useDataOrdenesAbiertas";
import { Table } from "antd";
import useFetch from "../../../utils/useFetch";

export const TablaOrdenesAbiertas = () => {
  const {columns} = useDataOrdenesAbiertas();

  const {data = [], isLoading} = useFetch("/APFacturacion/GetOrdenes")
  return (
    <>
      <Table size='small' loading={isLoading} rowKey={'ordenPagoID'} dataSource={data} columns={columns}/>
    </>
  )
}