import { Form, Input, Modal, Select } from "antd";
import { URL_BASE_ACCESOPROVEEDORES } from "../../../constants/url";
import { useSelector } from "react-redux";
import { axiosClientSigsa } from "../../../lib/axios/axiosClientSigsa";

const postPropuestaCarretero = async (obj) => {
  const post = await axiosClientSigsa.post(
    `${URL_BASE_ACCESOPROVEEDORES}/PostPropuestaCarretero`,
    obj
  );
};

const FormaPropuestaModal = ({open, setOpenModalCarretero, asistenciaId}) => {
  const [ form ] = Form.useForm();
  const {sucursales} =
    useSelector((state) => state.proveedorInfoReducer.proveedorInfo);

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        const obj = {
          ...values,
          asistenciaId
        };
        postPropuestaCarretero(obj);
        form.resetFields();
        setOpenModalCarretero(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title="Proponer Carretero"
      open={open}
      onCancel={() => setOpenModalCarretero(false)}
      onOk={onOk}
    >
      <Form form={form}>
        <div style={{display: "flex", gap: 15}}>
          <Form.Item style={{width: "100%"}}>
            <div className="inputLabel">Sucursal:</div>
            <Form.Item
              name="proveedorSucursalId"
              rules={[
                {
                  required: true,
                  message: "Selecciona una sucursal",
                },
              ]}
            >
              <Select options={sucursales} fieldNames={{label: 'nombre', value: 'proveedorSucursalID'}}
                      style={{width: "100%"}}/>
            </Form.Item>
          </Form.Item>

          <Form.Item style={{width: "100%"}}>
            <div className="inputLabel">Costo:</div>
            <Form.Item
              name="costo"
              rules={[
                {
                  required: true,
                  message: "Ingresa el costo del servicio",
                },
              ]}
            >
              <Input type="number" style={{width: "100%"}}/>
            </Form.Item>
          </Form.Item>

          <Form.Item style={{width: "100%"}}>
            <div className="inputLabel">Tiempo de llegada:</div>
            <Form.Item
              name="tiempoLlegada"
              rules={[
                {
                  required: true,
                  message: "Ingresa el tiempo de llegada",
                },
              ]}
            >
              <Input type="number"/>
            </Form.Item>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default FormaPropuestaModal;
