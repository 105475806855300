import { Button, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { axiosClientSigsa } from "../../../../lib/axios/axiosClientSigsa";

export const ButtonUpload = ({url, size = 'middle', name = 'file', body, fn, title, accept = '*', disabled}) => {
  const uploadFile = async (file) => {
    const formData = new FormData()
    for (const key in body) {
      formData.append(key, body[key])
    }
    formData.append(name, file.file)
    try {
      const {data} = await axiosClientSigsa.post(url, formData)
      if (fn) {
        fn()
      }
      message.success(data)
    } catch (e) {
      message.error(e.response.data)
    }
  }
  return (
    <>

      <Upload   fileList={[]} maxCount={1} accept={accept} onChange={uploadFile}
              beforeUpload={() => false}>
        <Button disabled={disabled} size={size} icon={<UploadOutlined/>}>{title} </Button>
      </Upload>
    </>
  )
}