import { useEffect, useState } from 'react';
import { axiosClientSigsa } from "../lib/axios/axiosClientSigsa";

const useFetch = (url) => {
  const [ data, setData ] = useState(null);
  const [ isLoading, setLoading ] = useState(true);
  const [ error, setError ] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axiosClientSigsa(url);
        setData(response.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    void fetchData();
  }, [ url ]);

  return {data, isLoading, error};
};

export default useFetch;