import { types } from "../types/types";
import { axiosClientSigsa } from "../../lib/axios/axiosClientSigsa";
import { composeErrorNotification } from "../../utils/composeNotification";


export const getItemsOrdenFacturacion = (id) => {
  return async (dispatch) => {
    try {
      const result = await axiosClientSigsa(`/APFacturacion/GetItemsOP/${id}`)
      dispatch(saveItemsFacturacion(result.data))
    } catch (e) {
      dispatch(composeErrorNotification("GetItemsOP", e.response.data))
    }
  }
};


export const saveItemsFacturacion = (items) => {
  return {
    type: types.saveItemsFacturacion,
    payload: items,
  };
}

export const getDetailFactura = (id) => {
  return async (dispatch) => {
    try {
      const result = await axiosClientSigsa(`/APFacturacion/GetOrden/${id}`)
      dispatch(saveDetailFactura(result.data))
    } catch (e) {
      dispatch(composeErrorNotification("GetDetailFactura", e.response.data))
    }
  }
}


export const saveDetailFactura = (detail) => {
  return {
    type: types.saveDetailFactura,
    payload: detail,
  };
}

export const handleVersion = (value) => {
  return (dispatch) => {
    dispatch(handleModal(value))
  }
}


export const showModal  = (value) => {
  return (dispatch) => {
    dispatch(showModalAction(value))
  }
}


export const handleModal = (value) => {
  return {
    type: types.handleModalVersion,
    payload: value
  };
}

export const showModalAction  = (value) => {
  return {
    type: types.showModal,
    payload: value
  }
}