import { types } from "../types/types";

const init = {
  itemsFacturacion: [],
  detailsFactura: {},
  isValidVersion: true,
  showModal: 'show'
};

export const facturacionReducer = (state = init, action) => {
  switch (action.type) {
    case types.saveItemsFacturacion:
      return {
        ...state,
        itemsFacturacion: action.payload,
      };
    case types.handleModalVersion:
      return {
        ...state,
        isValidVersion: action.payload
      }
    case types.showModal:
      return {
        ...state,
        showModal: action.payload
      }
    case types.saveDetailFactura:
      return {
        ...state,
        detailsFactura: action.payload,
      };
    default:
      return state;
  }
};
