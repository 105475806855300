import { ControlesCrearOrden } from "./ControllesCrearOrden";
import { TablaCrearOrden } from "./TablaCrearOrden";

export const Fase1 = () => {
  return (
    <>
      <ControlesCrearOrden />
      <TablaCrearOrden/>
    </>
  )
}