import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { allReducers } from "./allReducers";

const reducers = combineReducers(allReducers);

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' && typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);
