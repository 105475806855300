import { formatNumberIntl } from "../../../utils/formatNumberIntl";
import { useHistory } from "react-router";
import styles from '../Facturacion.module.css'
import { EyeOutlined } from "@ant-design/icons";
import { DayjsConfig } from "../../../lib/Dayjs/DayjsConfig";

const format = DayjsConfig("DD/MM/YYYY");
export const useDataOrdenesAbiertas = () => {
  const navigate = useHistory();
  const columns = [
    {title: "OrdenId", dataIndex: "ordenPagoID", key: "ordenPagoID"},
    {title: "Fecha", dataIndex: "fechaCreacion", key: "fechaCreacion", render: (fecha) => format(fecha)},
    {title: 'Cliente', dataIndex: 'clienteNombre', key: 'clienteNombre'},
    {title: 'Estatus', dataIndex: 'estatus', key: 'estatus'},
    {
      title: 'Monto',
      dataIndex: 'total',
      key: 'total',
      render: (monto) => {
        return formatNumberIntl(monto);
      },
    },
    {
      title: 'Acciones',
      dataIndex: 'acciones',
      key: 'acciones',
      render: (_, data) => {
        return (
          <div>
            <EyeOutlined
              className={styles.eye_icon}
              onClick={() => navigate.push(`/facturacion/orden-pago/${data.ordenPagoID}`)}
            />
          </div>
        )
      }
    }
  ]

  return {columns}
}