import { DayjsConfig } from "../../../lib/Dayjs/DayjsConfig";
import { ButtonUpload } from "../InformacionOrden/components/ButtonUpload";
import { Button, message, Tooltip } from "antd";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { axiosClientSigsa } from "../../../lib/axios/axiosClientSigsa";
import { useDispatch, useSelector } from "react-redux";
import { getItemsOrdenFacturacion } from "../../../context/actions/facturacionActions";
import { useParams } from "react-router";
import { formatNumberIntl } from "../../../utils/formatNumberIntl";

const format = DayjsConfig("DD/MM/YYYY");
export const useColumnsCrearOrden = () => {
  const dispatch = useDispatch()
  const {id} = useParams()

  const ordenDetails = useSelector(state => state.facturacionReducer.detailsFactura)
  const deleteOrden = async (ordenPagoId) => {
    try {
      await axiosClientSigsa(`APFacturacion/RemoveItemOP/${ordenPagoId}`)
      message.success('Eliminado')
      void dispatch(getItemsOrdenFacturacion(id))
    } catch (e) {
      message.error(e.response.data)
    }

  }
  const columns = [
    {title: 'Asistencia ID', dataIndex: 'asistenciaID', key: 'asistenciaID'},
    {title: 'Fecha', dataIndex: 'fecha', key: 'fecha', render: (fecha) => format(fecha)},
    {title: 'Cuenta', dataIndex: 'cuenta', key: 'cuenta'},
    {title: 'Estatus Final', dataIndex: 'estatusFinal', key: 'estatusFinal'},
    {title: 'Costo', dataIndex: 'montoTotal', key: 'montoTotal', render: (monto) => formatNumberIntl(monto)},
    {
      title: 'Tiene Placas',
      render: (row) => row.tienePlaca ? <CheckCircleOutlined style={{color: 'green', fontSize: '18px'}}/> :
        <ButtonUpload
          fn={() => dispatch(getItemsOrdenFacturacion(id))}
          url={'/APDocumentos/PostPlaca'}
          body={{asistenciaID: row.asistenciaID}}
          title={'Cargar placas'}
        />

    },
    {
      title: '',
      render: (row) => <>{(ordenDetails.estatusOrdenPagoID === 1 || ordenDetails.estatusOrdenPagoID === 2) &&
        <Tooltip title={'Eliminar'}> <Button onClick={() => deleteOrden(row.ordenPagoItemID)}
                                             icon={<DeleteOutlined/>} type={'text'}
                                             danger></Button></Tooltip>}
      </>
    }
  ]
  return {columns}
}
