import { Tooltip } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";

export const StatusFile = ({archivoValido, message, estaCargada}) => {
  if (!estaCargada) {
    return null
  }
  return (
    <>
      <div style={{marginLeft: '10px'}}>

        {archivoValido === true &&
          <Tooltip title={message ?? 'Archivo valido'}> <CheckCircleOutlined style={{color: 'green'}}/></Tooltip>}

        {archivoValido === 'false' &&
          <Tooltip title={message ?? 'Rechazado'}> <CloseCircleOutlined style={{color: 'red'}}/> </Tooltip>}

        {archivoValido === null &&
          <Tooltip placement="top" title={'Archivo en proceso de validación'}><InfoCircleOutlined
            style={{color: 'blue'}}/> </Tooltip>}
      </div>
    </>
  )
}