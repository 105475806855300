import { useColumnsInformacion } from "../hooks/useColumnsInformacion";
import { Table } from "antd";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getItemsOrdenFacturacion } from "../../../../context/actions/facturacionActions";

export const TablaInformacionOrden = () => {


  const {columns} = useColumnsInformacion()
  const {id} = useParams()
  const dispatch = useDispatch()

  const items = useSelector(state => state.facturacionReducer.itemsFacturacion)

  useEffect(() => {
    void dispatch(getItemsOrdenFacturacion(id))
  }, []);
  return (<>
    <Table size={'small'} columns={columns} dataSource={items}/>
  </>)
}