import { LabelValor } from "../../../../components/label/LabelValor";
import { formatNumberIntl } from "../../../../utils/formatNumberIntl";
import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import { DayjsConfig } from "../../../../lib/Dayjs/DayjsConfig";

export const DetallesOrden = () => {


  const detailsFacture = useSelector(state => state.facturacionReducer.detailsFactura)


  const format = DayjsConfig('DD-MM-YYYY')

  return (
    <>
      <Row gutter={[10, 10]}>

        <Col span={8} style={{marginBottom: '15px'}}>
          <LabelValor label={"OrdenId"} valor={detailsFacture.ordenPagoID}/>
        </Col>

        <Col span={8}>
          <LabelValor label={"Fecha"} valor={format(detailsFacture.fechaCreacion)}/>
        </Col>

        <Col span={8}>
          <LabelValor label={"Cuenta"} valor={""}/>
        </Col>

      </Row>
      <Row gutter={16}>
        <Col span={8}>

          <LabelValor label={"Estatus"} valor={detailsFacture.estatus}/>
        </Col>

        <Col span={8}>

        </Col>
        <Col span={8}>

          <LabelValor label={"Monto"} valor={formatNumberIntl(detailsFacture?.total)}/>
        </Col>

      </Row>

    </>
  )
}