import { Badge, Col, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { startConfirmarProveedor } from "../../../context/actions/asistenciasActions";
import { startUpdateServicioGnp } from "../../../context/actions/gnpApiActions";
import ModalPage from "../../../layout/ModalPage";
import { truncateString } from "../../../utils/truncateString";
import { Crono } from "./Crono";
import { FormaAceptarServicio } from "./FormaAceptarServicio";
import FormaPropuestaModal from "./FormaPropuestaModal";

const RowAsistenciaPorConfirmar = ({ asistencia, provAsignado }) => {
  const dispatch = useDispatch();

  const [openModalCarretero, setOpenModalCarretero] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [asistenciaSelected, setAsistenciaSelected] = useState();

  const onClick = (asistencia) => {
    setAsistenciaSelected(asistencia.asistenciaID);

    if (asistencia.localCarretero === "local") {
      setOpenModal(true);
    }

    if (asistencia.localCarretero === "carretero") {
      setOpenModalCarretero(true);
    }
  };

  const handleOk = () => {
    dispatch(startConfirmarProveedor(asistenciaSelected, asistencia));

    if (asistenciaSelected.esGnpApi)
      // confirmaos en api de gnp
      dispatch(
        startUpdateServicioGnp({
          folioAsignacion: asistenciaSelected.folioGnp,
          estatusActual: "EA",
        })
      );
    setOpenModal(false);
  };

  return (
    <>
      <Badge.Ribbon
        text={
          !provAsignado && !asistencia.propuesta
            ? asistencia.localCarretero
            : provAsignado && asistencia.localCarretero === 'carretero'
            ? "No asignado por tiempo / costo"
            : asistencia.propuesta && "Propuesta enviada"
        }
        color={
          !provAsignado && !asistencia.propuesta
            ? asistencia.localCarretero === "local"
              ? "green"
              : "blue"
            : provAsignado
            ? "red"
            : "#faad14"
        }
      >
        <Row
          gutter={10}
          style={{ marginBottom: 15 }}
          className="rowAsistencias"
          onClick={() => onClick(asistencia)}
        >
          <Col span={2}>
            {!provAsignado && (
              <Crono fechaSugiereProveedor={asistencia.fechaSugiereProveedor} />
            )}
          </Col>
          <Col span={4} style={{ paddingTop: 4 }}>
            {truncateString(
              asistencia.nombreLlamada +
                " " +
                asistencia.apellidoPaternoLlamada +
                " " +
                asistencia.apellidoMaternoLlamada,
              26
            )}
          </Col>

          <Col span={4} style={{ paddingTop: 4 }}>
            {asistencia.servicioNombre}
          </Col>
          <Col span={5} style={{ paddingTop: 4 }}>
            {asistencia.municipioOrigen}, {asistencia.estadoOrigen}
          </Col>

          <Col span={5} style={{ paddingTop: 4 }}>
            {asistencia.municipioDestino}, {asistencia.estadoDestino}
          </Col>
        </Row>
      </Badge.Ribbon>

      {/* //Modal locales */}
      {!provAsignado && (
        <ModalPage
          handleOk={handleOk}
          handleCancel={() => setOpenModal(false)}
          isVisible={openModal}
          titulo="Aceptar Servicio"
        >
          <FormaAceptarServicio />
        </ModalPage>
      )}

      {/* //Modal carretero */}
      {!asistencia.propuesta && (
        <FormaPropuestaModal
          open={openModalCarretero}
          setOpenModalCarretero={setOpenModalCarretero}
          asistenciaId={asistencia.asistenciaID}
        />
      )}
    </>
  );
};

export default RowAsistenciaPorConfirmar;
