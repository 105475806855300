import { Button, notification, Popconfirm } from "antd";
import { useHistory } from "react-router";
import { axiosClientSigsa } from "../../../lib/axios/axiosClientSigsa";
import { URL_BASE } from "../../../constants/url";

export const ControlesOrdenesAbiertas = ({style}) => {
  const history = useHistory();

  const createNewOrder = async () => {
    await axiosClientSigsa.post(`${URL_BASE}/api/APFacturacion/CrearOP`).then(res => {
      history.push(`/facturacion/orden-pago/${res.data.ordenPagoID}`);
    }).catch(e => {
        notification.error({message: 'Error', description: e});
      }
    )

  }

  return (
    <>
      <div style={{display: 'flex', justifyContent: 'flex-end', ...style}}>
        <Popconfirm title={"¿Está seguro que desea crear una nueva OP?"} okText="Si" cancelText="No"
                    onConfirm={createNewOrder}>
          <Button style={{marginRight: '10px'}} danger>Crear nueva OP</Button>
        </Popconfirm>
      </div>
    </>
  )
}