import { Col, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import LabelNumber from "../../../components/input/LabelNumber";
import { SelectSucursales } from "../../../components/select/SelectSucursales";
import { setConfirmaAsistenciaData } from "../../../context/actions/asistenciasActions";

export const FormaAceptarServicio = ({ setData }) => {
  const dispatch = useDispatch();
  const changeSelect = (proveedorSucursalID) => {
    dispatch(setConfirmaAsistenciaData({ proveedorSucursalID }));
  };

  const handleChange = ({ target }) => {
    const tiempoLlegada = Number(target.value);
    dispatch(setConfirmaAsistenciaData({ tiempoLlegada }));
  };

  return (
    <Row gutter={10}>
      <Col span={12}>
        <SelectSucursales label="Sucursales" onChange={changeSelect} />
      </Col>
      <Col span={12}>
        <LabelNumber label="Tiempo de llegada" onChange={handleChange} />
      </Col>
    </Row>
  );
};
