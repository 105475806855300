import { consLogged } from "../../constants/consLogged";
import { types } from "../types/types";

const init = {
  logged: consLogged.INICIANDO,
};
export const userReducer = (state = init, action) => {
  switch (action.type) {
    case types.storeUserData:
      return {
        ...state,
        user: action.payload,
      };

    case types.setLogged:
      return {
        ...state,
        logged: action.payload,
      };

    default:
      return state;
  }
};
