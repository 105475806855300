import { UploadItem } from "./UploadItem";
import { useDispatch, useSelector } from "react-redux";
import { getDetailFactura } from "../../../../context/actions/facturacionActions";
import { useParams } from "react-router";

export const UploadZone = () => {
  const dispatch = useDispatch();
  const details = useSelector(state => state.facturacionReducer.detailsFactura)
  const {id} = useParams()
  const getDetails = () => {
    dispatch(getDetailFactura(id))
  }
  return (
    <>

        <UploadItem
          estaCargada={details?.estaCargadaFacturaXml}
          fn={getDetails}
          accept={'.xml'}
          title={"Cargar factura XML"} tipoDocumento={1}
          boolean={details?.facturaXMLEsValida}
          disabled={details?.estaCargadaFacturaXml && details?.facturaXMLEsValida || details?.estaCargadaFacturaXml && details?.facturaXMLEsValida === null}
          message={details?.facturaXMLMotivoRechazo}
          color={'red'}
        />

        <UploadItem
          fn={getDetails}
          estaCargada={details?.estaCargadaFactura }
          accept={'.pdf, image/*'}
          disabled={ details?.estaCargadaFactura && details?.facturaEsValida ||  details?.estaCargadaFactura  && details?.facturaEsValida === null }
          title={"Cargar factura PDF"} tipoDocumento={2} color={'red'}
          boolean={details?.facturaEsValida} message={details?.facturaMotivoRechazo}/>


        <UploadItem
          estaCargada={details?.estaCargadaVerificacion}
          disabled={details?.estaCargadaVerificacion && details?.verificacionFacturaEsValida || details?.estaCargadaVerificacion && details?.verificacionFacturaEsValida === null}
          fn={getDetails}
          boolean={details?.verificacionFacturaEsValida} message={details?.verificacionFacturaMotivoRechazo}
          title={"Cargar validación SAT"} tipoDocumento={3} color={'red'}
        />

    </>
  )
}