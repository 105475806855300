import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startGetAsistenciasVivas } from "../../../context/actions/asistenciasActions";
import CardPage from "../../../layout/CardPage";
import { TablaHeader } from "./TablaHeader";
import { TableBody } from "./TableBody";

export const AsistenciasVivas = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startGetAsistenciasVivas());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(startGetAsistenciasVivas());
    }, 120000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const { asistenciasVivas } = useSelector((state) => state.asistenciasReducer);

  return (
    <CardPage titulo={`Asistencias Vivas ${asistenciasVivas.length}`}>
      <TablaHeader />
      <TableBody />
    </CardPage>
  );
};
