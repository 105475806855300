import { DetallesOrden } from "../../InformacionOrden/components/DetallesOrden";
import { UploadZone } from "../../InformacionOrden/components/UploadZone";
import { Flex } from "antd";
import { TablaInformacionOrden } from "../../InformacionOrden/components/TablaInformacionOrden";
import { UploadItem } from "../../InformacionOrden/components/UploadItem";

export const Fase2 = () => {
  return (
    <>
      <h3 className='titulo' style={{margin: 0, marginBottom: '20px', color: '#ca2228'}}>Detalles Orden</h3>
      <Flex gap={20} justify="space-between">

        <article style={{width: '50%'}}>
          <DetallesOrden/>
        </article>

        <article style={{width: '40%'}}>
          <UploadZone/>

          <UploadItem title={"Cargar comprobante de pago"} tipoDocumento={4}
                      color={'red'}/>
        </article>

      </Flex>

      <section className='titulo' style={{margin: 0, marginBottom: '20px',marginTop: '20px', color: '#ca2228'}}>
        <h3>Tabla: resumen orden de pago</h3>
        <TablaInformacionOrden/>
      </section>
    </>
  )
}