import { Button, Modal } from "antd";
import React from "react";

export default function ModalPage({
  titulo,
  isVisible,
  children,
  handleOk,
  handleCancel,
}) {
  return (
    <Modal
      title={titulo}
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button size="small" key="back" onClick={handleCancel}>
          Cerrar
        </Button>,
        <Button size="small" type="primary" key="back" onClick={handleOk}>
          Ok
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
}
