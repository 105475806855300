import CardPage from "../../../layout/CardPage";
import { DetallesOrden } from "./components/DetallesOrden";
import { TablaInformacionOrden } from "./components/TablaInformacionOrden";
import { StatusZone } from "./components/StatusZone";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDetailFactura, saveDetailFactura } from "../../../context/actions/facturacionActions";
import { ControlesCrearOrden } from "../ordenPago/Fase1/ControllesCrearOrden";

export const InformacionOrden = () => {
  const history = useHistory()

  const dispatch = useDispatch();

  const {id} = useParams()


  useEffect(() => {
    void dispatch(getDetailFactura(id))
    return () => {
      dispatch(saveDetailFactura({}))
    }
  }, [ dispatch, id ]);

  const detailsFacture = useSelector(state => state.facturacionReducer.detailsFactura)
  return (
    <>
      <CardPage titulo={"Información de la orden"} tituloBoton={'Regresar'} tituloBoton2={"Agregar asistencias"}
                accionBoton2={() => history.push(`/facturacion/${id}/crear-orden`)}
                accionBoton={() => history.push('/facturacion/ordenes-abiertas')}>
        <section style={{marginBottom: '50px'}}>

          <DetallesOrden/>

        </section>

        <section style={{marginBottom: '20px'}}>

          <div style={{height: '150px'}}>
            <StatusZone status={detailsFacture.estatusOrdenPagoID}/>
          </div>

        </section>

        <section>

          <ControlesCrearOrden/>
          <TablaInformacionOrden/>

        </section>

      </CardPage>
    </>
  )
}