import { Alert, Button, Form, Input } from "antd";
import { useParams } from "react-router";
import { axiosClientSigsa } from "../../../../lib/axios/axiosClientSigsa";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItemsOrdenFacturacion } from "../../../../context/actions/facturacionActions";

export const ControlesCrearOrden = ({style}) => {
  const {id} = useParams()
  const [ error, setError ] = useState('')
  const [ data, setData ] = useState('')
  const [ loading, setLoading ] = useState(false)

  const dispatch = useDispatch()

  const details = useSelector(state => state.facturacionReducer.detailsFactura)

  const onFinish = async (values) => {
    const body = {ordenPagoID: id, asistenciaID: values?.asistenciaID}

    try {
      setData('')
      setLoading(true)
      const data = await axiosClientSigsa.post('APFacturacion/AgregarItemOP', body)
      setData(data.data)
      setError('')
      void dispatch(getItemsOrdenFacturacion(id))
    } catch (e) {
      setError(e?.response?.data || e?.message || '')
    } finally {
      setLoading(false)
    }

  }

  return (
    <>
      <Form
        autoComplete={"off"}
        disabled={!(details.estatusOrdenPagoID === 1 || details.estatusOrdenPagoID === 2)}
        onFinish={onFinish}
        layout={'inline'}
        style={{...style, marginTop: '10px', marginBottom: "10px"}}>
        <Form.Item required={true} name={"asistenciaID"} rules={[ {required: true, message: ''} ]}
                   style={{marginBottom: '10px'}}>
          <Input allowClear placeholder="Servicio ID" style={{width: '200px'}}/>
        </Form.Item>

        <Button htmlType={'submit'} loading={loading}>Agregar servicio</Button>
      </Form>
      <div style={{minHeight: '40px', marginBottom: '15px'}}>
        {error && <Alert message={`${error}`} type="error" showIcon/>}
        {data && <Alert message={`${data}`} type="success" showIcon/>}
      </div>
    </>
  )
}