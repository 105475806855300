import { types } from "../types/types";

const initial = {
  asistenciasPorConfirmar: [],
  asistenciasVivas: [],
  confirmaAsistenciaData: {},
  openLateral: false,
  asistencia: {},
  archivos: [],
  cargandoAsistencia: false,
};

export const asistenciasReducer = (state = initial, action) => {
  switch (action.type) {
    case types.setCargandoAsistencia:
      return {
        ...state,
        cargandoAsistencia: action.payload,
      };
    case types.storeArchivos:
      return {
        ...state,
        archivos: action.payload,
      };
    case types.storeAsistencia:
      return {
        ...state,
        asistencia: action.payload,
      };
    case types.setOpenLateral:
      return {
        ...state,
        openLateral: action.payload,
      };
    case types.setConfirmaAsistenciaData:
      return {
        ...state,
        confirmaAsistenciaData: {
          ...state.confirmaAsistenciaData,
          ...action.payload,
        },
      };

    case types.storeAsistenciasPorConfirmar:
      return {
        ...state,
        asistenciasPorConfirmar: action.payload,
      };
    case types.storeAsistenciasVivas:
      return {
        ...state,
        asistenciasVivas: action.payload,
      };

    default:
      return state;
  }
};
